import { convertPriceByCurrency } from '@/Configuration/Service';
import { priceEnum } from '@/Interface/interface';
import { Context } from '@/pages/_app';
import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react';

const PriceComponent = ({ price, name }: any) => {
  const {
    isCurrencyCodeDropDownChange,
    currencyDropDownValue,
  } = useContext(Context);
  // @ts-ignore
  const currencyCode = priceEnum[Cookies.get('CountryCode')];
  const [displayPrice, setDisplayPrice] = useState(
    convertPriceByCurrency({ ['USD']: price?.['USD'] }),
  );

  useEffect(() => {
    if (currencyCode !== undefined && price?.[currencyCode] !== undefined) {
      setDisplayPrice(
        convertPriceByCurrency({ [currencyCode]: price[currencyCode] }),
      );
    }
  }, [currencyDropDownValue, isCurrencyCodeDropDownChange, price]);

  return <>{displayPrice}</>;
};

export default PriceComponent;
